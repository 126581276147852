div.calendar-layout {
  display: flex;
  width: 100%;

  div.panel {
    flex: 0 0 200px;

    a.logo {
      text-decoration: none;
      color: black;

      h1 {
        font-family: Bezumnoe3;
        font-size: 40pt;
        margin: 0;
        padding: 0;
      }
    }

    h2 {
      font-family: Bezumnoe3;
      font-size: 12pt;
      text-transform: uppercase;
      margin: 0 0 40px 10px;
    }

    button {
      margin-top: 20px;
    }
  }

  div.content {
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
