$workdays-color: #2d7b91;
$weekends-color: #d45f1c;

time.date-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3px;

  width: 40px;
  height: 40px;

  &.weekend div {
    background-color: $weekends-color;
    &.month {
      border-color: $weekends-color;
    }
  }

  div {
    &.month {
      font-weight: bold;
      font-size: 8pt;
      background-color: #FFF;
      color: #727677;
      margin: 0;
      border-color: $workdays-color;
      border-style: solid;
      border-width: 1px 1px 0 1px;
      border-radius: 3px 3px 0 0;
    }
    color: #FFF;
    background-color: $workdays-color;
    text-align: center;
    padding: 0;
    font-family: sans-serif;
    font-size: 17pt;
    border-radius: 0 0 3px 3px;
  }
}
