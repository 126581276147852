ul.calendars-list {
  padding: 0;
  li {
    list-style: none;
    margin-bottom: 5px;

    span {
      font-size: 7pt;
      font-family: sans-serif;
      border: solid 1px #DDD;
      border-radius: 6px;
      padding: 2px 4px;
      margin-left: 4px;
      text-decoration: none;
    }
  }
}
