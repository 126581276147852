ul.calendar-events {
  li {
    margin-bottom: 25px;
    list-style: none;

    header {
      display: flex;
      gap: 12px;

      h2 {
        margin: 0;
        span {
          background-color: #c55098;
          color: #FFF;
          font-family: sans-serif;
          font-size: 50%;
          text-transform: uppercase;
          border-radius: 4px;
          font-weight: normal;
          padding: 4px 6px;
          position: relative;
          top: -4px;
        }
      }
      i {
        margin-bottom: 10px;
        display: block;

        span:not(:first-child):not(:empty) {
          &:before {
            content: ", ";
          }
        }
      }
    }

    summary {
      white-space: pre-wrap;

    }

    footer {
      margin-top: 10px;
    }
  }
}
