div.client {
  flex: 1;
  font-size: 5em;
  align-self: center;
  text-align: center;

  h1 {
    font-family: Bezumnoe3;
    font-size: 70pt;
    margin: 0;
    padding: 0;
  }

  ul.statuses {
    width: 250px;
    font-size: 14px;
    display: inline-block;
    padding: 20px 0 0;

    li {
      display: flex;
      font-family: arial, tahoma;
      height: 20px;
      b {
        flex: 1 0;
        overflow-x: hidden;

        &:after {
          font-weight: 100;
          content: '............................................................................';
          color: #EEE;
        }
      }
      span {
        flex: 0 0;
        white-space: nowrap;
        text-align: right;

        &.failure {
          color: red;
        }
      }
    }
  }
}
