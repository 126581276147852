@font-face {
  font-family: "Bezumnoe3";
  src: url("./fonts/BezumnoeNeue-Regular.otf");
  src: url("./fonts/BezumnoeNeue-Regular.ttf") format("truetype");
}

body {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;

  a {
    color: #999;
    text-decoration: dotted underline;
  }
}
